<template>
  <SideBar :groups="groups"></SideBar>
</template>

<script>
import SideBar from '@/components/SideBar'
export default {
  components: {
    SideBar
  },

  data() {
    return {
      groups: [
        {
          label: '项目展示',
          list: [
            {
              name: '项目分类',
              path: '/mall/project/category'
            },
			{
			  name: '项目标签',
			  path: '/mall/project/label'
			},
            {
              name: '项目列表',
              path: '/mall/project/project'
            }
          ]
        }
      ]
    }
  }
}
</script>
